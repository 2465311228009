import styled from 'styled-components';

export const App = () => {
  return (
    <section className="container-fluid header-section about-page-header">
      <Header className="content container">
        <h2>David Liu</h2>
        <Tagline>
          <span role="img" aria-label="graduating">
            👨‍💻
          </span>{' '}
          Currently a Product Manager @ <a href="https://cognite.com">Cognite</a>.
          <br />
          <span role="img" aria-label="graduating">
            💻
          </span>{' '}
          Prev. Senior Software Enginner, worked with Full Stack + Mobile 
          <br />
          <span role="img" aria-label="celebrate">
            🤩
          </span>{' '}
          I like working on tools that improve somebody's day.
        </Tagline>
        <LinkSection>
          <ResumeButton
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer"
            href="https://drive.google.com/file/d/1JLbEwVXFuY8GTY2pa2c6zWThZXho409s/view?usp=sharing"
          >
            <i className="material-icons" style={{ color: 'white' }}>
              &#xE85D;
            </i>
            View Resume
          </ResumeButton>
          <SocialSection>
            <a href="mailto:david@davidtheliu.com" rel="noreferrer">
              <span className="fa-stack fa-lg">
                <i className="fa fa-envelope fa-stack-1x fa-inverse" />
              </span>
            </a>
            <a href="https://www.linkedin.com/in/davidtheliu" rel="noreferrer">
              <span className="fa-stack fa-lg">
                <i className="fa fa-linkedin fa-stack-1x fa-inverse" />
              </span>
            </a>
            <a
              href="https://open.spotify.com/user/11158275241?si=EQcNOL1zTKSaksLY5lShOQ"
              rel="noreferrer"
            >
              <span className="fa-stack fa-lg">
                <i className="fa fa-spotify fa-stack-1x fa-inverse" />
              </span>
            </a>
            <a href="https://www.instagram.com/davidthephotos" rel="noreferrer">
              <span className="fa-stack fa-lg">
                <i className="fa fa-instagram fa-stack-1x fa-inverse" />
              </span>
            </a>
          </SocialSection>
        </LinkSection>
      </Header>
    </section>
  );
};

const Header = styled.div`
  @media (max-width: 992px) {
    padding: 24px;
  }
`;

const LinkSection = styled.div`
  margin-top: 24px;
  padding: 0;
`;

const ResumeButton = styled.a`
  margin-bottom: 10px;
  margin-top: 10px;
`;

const SocialSection = styled.div`
  display: inline-flex;
  align-items: center;
  && > a {
    display: inline-block;
    margin: 0 5px;
  }
  && > a .fa,
  && > a .fa {
    color: #787878;
  }

  && > a:hover .fa-circle,
  && > a:hover .fa-circle {
    color: rgba(69, 173, 168, 0.7);
  }
`;

const Tagline = styled.h1`
  margin-bottom: 16px;
  font-size: 26px !important;
  line-height: 1.6;
  font-weight: 300 !important;
  display: inline-block;

  @media (max-width: 992px) {
    font-size: 18px !important;
  }
`;
